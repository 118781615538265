<template>
    <div class="showForm scrolling">
        <div class="title">公文单</div>
        <formTable :myData="myData" />
    </div>
</template>
<script>
import formTable from '../components/formTable'
export default {
    components: {
        formTable,
    },
    data() {
        return {
            myData: {},
        }
    },
    mounted() {
        this.myData = { ...this.$store.state.mobile.addBillMess }
    },
}
</script>
<style lang="less" scoped>
.showForm {
    padding: 12px;
    .title {
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #1c1c1c;
        padding-left: 5px;
        margin-bottom: 8px;
        border-left: 2px solid #3b9afa;
        line-height: 1;
    }
}
</style>
